export const routePaths = {
	LANDING: "/",

	LOAN_FORM: "/loan/loanform/",
	LOAN_VIEW: "/loan/loanview/",
	APPRAISER_LOGIN: "/appraiser/",
	HOME_SCREEN: "/appraiser/home/",
	EDIT_APPLICATION: "/appraiser/home/editloanform/",
	// APPLICATION_FORWARD: "/appraiser/home/fwdapplication/",
	// EDIT_APPLICATION_FORWARD: "/appraiser/home/fwdapplication/editloanform/",

	BRANCH_MANAGER_LOGIN: "/branchmanager/",
	BRANCH_MANAGER_HOME: "/branchmanager/homebr/",
	BR_EDIT_APPLICATION: "/branchmanager/homebr/editloanform/",
	// BR_APPLICATION_FORWARD: "/branchmanager/homebr/fwdapplication/",
	// BR_EDIT_APPLICATION_FORWARD:
	// 	"/branchmanager/homebr/fwdapplication/editloanform/",
	// BR_APPLICATION_REJECT: "/branchmanager/homebr/rejectapplication/",
	// BR_EDIT_APPLICATION_REJECT:
	// 	"/branchmanager/homebr/rejectapplication/editloanform/",

	CREDIT_MANAGER_LOGIN: "/creditmanager/",
	CREDIT_MANAGER_HOME: "/creditmanager/homecr/",
	CREDIT_MGR_EDIT_APPLICATION: "/creditmanager/homecr/editloanform/",

	CEO_LOGIN: "/ceo/",
	CEO_HOME: "/ceo/homeceo/",
	CEO_EDIT_APPLICATION: "/ceo/homeceo/editloanform/",
}
